import _outlayer from "outlayer";
import _getSize from "get-size";
import _desandroMatchesSelector from "desandro-matches-selector";
import _fizzyUiUtils from "fizzy-ui-utils";
import _item from "./item";
import _layoutMode from "./layout-mode";
import // include default layout modes
_masonry from "./layout-modes/masonry";
import _fitRows from "./layout-modes/fit-rows";
import _vertical from "./layout-modes/vertical";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*!
 * Isotope v3.0.6
 *
 * Licensed GPLv3 for open source use
 * or Isotope Commercial License for commercial use
 *
 * https://isotope.metafizzy.co
 * Copyright 2010-2018 Metafizzy
 */
(function (window, factory) {
  // universal module definition

  /* jshint strict: false */

  /*globals define, module, require */
  if (exports) {
    // CommonJS
    exports = factory(window, _outlayer, _getSize, _desandroMatchesSelector, _fizzyUiUtils, _item, _layoutMode, _masonry, _fitRows, _vertical);
  } else {
    // browser global
    window.Isotope = factory(window, window.Outlayer, window.getSize, window.matchesSelector, window.fizzyUIUtils, window.Isotope.Item, window.Isotope.LayoutMode);
  }
})(window, function factory(window, Outlayer, getSize, matchesSelector, utils, Item, LayoutMode) {
  'use strict'; // -------------------------- vars -------------------------- //

  var jQuery = window.jQuery; // -------------------------- helpers -------------------------- //

  var trim = String.prototype.trim ? function (str) {
    return str.trim();
  } : function (str) {
    return str.replace(/^\s+|\s+$/g, "");
  }; // -------------------------- isotopeDefinition -------------------------- //
  // create an Outlayer layout class

  var Isotope = Outlayer.create("isotope", {
    layoutMode: "masonry",
    isJQueryFiltering: true,
    sortAscending: true
  });
  Isotope.Item = Item;
  Isotope.LayoutMode = LayoutMode;
  var proto = Isotope.prototype;

  proto._create = function () {
    (this || _global).itemGUID = 0; // functions that sort items

    (this || _global)._sorters = {};

    this._getSorters(); // call super


    Outlayer.prototype._create.call(this || _global); // create layout modes


    (this || _global).modes = {}; // start filteredItems with all items

    (this || _global).filteredItems = (this || _global).items; // keep of track of sortBys

    (this || _global).sortHistory = ["original-order"]; // create from registered layout modes

    for (var name in LayoutMode.modes) {
      this._initLayoutMode(name);
    }
  };

  proto.reloadItems = function () {
    // reset item ID counter
    (this || _global).itemGUID = 0; // call super

    Outlayer.prototype.reloadItems.call(this || _global);
  };

  proto._itemize = function () {
    var items = Outlayer.prototype._itemize.apply(this || _global, arguments); // assign ID for original-order


    for (var i = 0; i < items.length; i++) {
      var item = items[i];
      item.id = (this || _global).itemGUID++;
    }

    this._updateItemsSortData(items);

    return items;
  }; // -------------------------- layout -------------------------- //


  proto._initLayoutMode = function (name) {
    var Mode = LayoutMode.modes[name]; // set mode options
    // HACK extend initial options, back-fill in default options

    var initialOpts = (this || _global).options[name] || {};
    (this || _global).options[name] = Mode.options ? utils.extend(Mode.options, initialOpts) : initialOpts; // init layout mode instance

    (this || _global).modes[name] = new Mode(this || _global);
  };

  proto.layout = function () {
    // if first time doing layout, do all magic
    if (!(this || _global)._isLayoutInited && this._getOption("initLayout")) {
      this.arrange();
      return;
    }

    this._layout();
  }; // private method to be used in layout() & magic()


  proto._layout = function () {
    // don't animate first layout
    var isInstant = this._getIsInstant(); // layout flow


    this._resetLayout();

    this._manageStamps();

    this.layoutItems((this || _global).filteredItems, isInstant); // flag for initalized

    (this || _global)._isLayoutInited = true;
  }; // filter + sort + layout


  proto.arrange = function (opts) {
    // set any options pass
    this.option(opts);

    this._getIsInstant(); // filter, sort, and layout
    // filter


    var filtered = this._filter((this || _global).items);

    (this || _global).filteredItems = filtered.matches;

    this._bindArrangeComplete();

    if ((this || _global)._isInstant) {
      this._noTransition((this || _global)._hideReveal, [filtered]);
    } else {
      this._hideReveal(filtered);
    }

    this._sort();

    this._layout();
  }; // alias to _init for main plugin method


  proto._init = proto.arrange;

  proto._hideReveal = function (filtered) {
    this.reveal(filtered.needReveal);
    this.hide(filtered.needHide);
  }; // HACK
  // Don't animate/transition first layout
  // Or don't animate/transition other layouts


  proto._getIsInstant = function () {
    var isLayoutInstant = this._getOption("layoutInstant");

    var isInstant = isLayoutInstant !== undefined ? isLayoutInstant : !(this || _global)._isLayoutInited;
    (this || _global)._isInstant = isInstant;
    return isInstant;
  }; // listen for layoutComplete, hideComplete and revealComplete
  // to trigger arrangeComplete


  proto._bindArrangeComplete = function () {
    // listen for 3 events to trigger arrangeComplete
    var isLayoutComplete, isHideComplete, isRevealComplete;

    var _this = this || _global;

    function arrangeParallelCallback() {
      if (isLayoutComplete && isHideComplete && isRevealComplete) {
        _this.dispatchEvent("arrangeComplete", null, [_this.filteredItems]);
      }
    }

    this.once("layoutComplete", function () {
      isLayoutComplete = true;
      arrangeParallelCallback();
    });
    this.once("hideComplete", function () {
      isHideComplete = true;
      arrangeParallelCallback();
    });
    this.once("revealComplete", function () {
      isRevealComplete = true;
      arrangeParallelCallback();
    });
  }; // -------------------------- filter -------------------------- //


  proto._filter = function (items) {
    var filter = (this || _global).options.filter;
    filter = filter || "*";
    var matches = [];
    var hiddenMatched = [];
    var visibleUnmatched = [];

    var test = this._getFilterTest(filter); // test each item


    for (var i = 0; i < items.length; i++) {
      var item = items[i];

      if (item.isIgnored) {
        continue;
      } // add item to either matched or unmatched group


      var isMatched = test(item); // item.isFilterMatched = isMatched;
      // add to matches if its a match

      if (isMatched) {
        matches.push(item);
      } // add to additional group if item needs to be hidden or revealed


      if (isMatched && item.isHidden) {
        hiddenMatched.push(item);
      } else if (!isMatched && !item.isHidden) {
        visibleUnmatched.push(item);
      }
    } // return collections of items to be manipulated


    return {
      matches: matches,
      needReveal: hiddenMatched,
      needHide: visibleUnmatched
    };
  }; // get a jQuery, function, or a matchesSelector test given the filter


  proto._getFilterTest = function (filter) {
    if (jQuery && (this || _global).options.isJQueryFiltering) {
      // use jQuery
      return function (item) {
        return jQuery(item.element).is(filter);
      };
    }

    if (typeof filter == "function") {
      // use filter as function
      return function (item) {
        return filter(item.element);
      };
    } // default, use filter as selector string


    return function (item) {
      return matchesSelector(item.element, filter);
    };
  }; // -------------------------- sorting -------------------------- //

  /**
   * @params {Array} elems
   * @public
   */


  proto.updateSortData = function (elems) {
    // get items
    var items;

    if (elems) {
      elems = utils.makeArray(elems);
      items = this.getItems(elems);
    } else {
      // update all items if no elems provided
      items = (this || _global).items;
    }

    this._getSorters();

    this._updateItemsSortData(items);
  };

  proto._getSorters = function () {
    var getSortData = (this || _global).options.getSortData;

    for (var key in getSortData) {
      var sorter = getSortData[key];
      (this || _global)._sorters[key] = mungeSorter(sorter);
    }
  };
  /**
   * @params {Array} items - of Isotope.Items
   * @private
   */


  proto._updateItemsSortData = function (items) {
    // do not update if no items
    var len = items && items.length;

    for (var i = 0; len && i < len; i++) {
      var item = items[i];
      item.updateSortData();
    }
  }; // ----- munge sorter ----- //
  // encapsulate this, as we just need mungeSorter
  // other functions in here are just for munging


  var mungeSorter = function () {
    // add a magic layer to sorters for convienent shorthands
    // `.foo-bar` will use the text of .foo-bar querySelector
    // `[foo-bar]` will use attribute
    // you can also add parser
    // `.foo-bar parseInt` will parse that as a number
    function mungeSorter(sorter) {
      // if not a string, return function or whatever it is
      if (typeof sorter != "string") {
        return sorter;
      } // parse the sorter string


      var args = trim(sorter).split(" ");
      var query = args[0]; // check if query looks like [an-attribute]

      var attrMatch = query.match(/^\[(.+)\]$/);
      var attr = attrMatch && attrMatch[1];
      var getValue = getValueGetter(attr, query); // use second argument as a parser

      var parser = Isotope.sortDataParsers[args[1]]; // parse the value, if there was a parser

      sorter = parser ? function (elem) {
        return elem && parser(getValue(elem));
      } : // otherwise just return value
      function (elem) {
        return elem && getValue(elem);
      };
      return sorter;
    } // get an attribute getter, or get text of the querySelector


    function getValueGetter(attr, query) {
      // if query looks like [foo-bar], get attribute
      if (attr) {
        return function getAttribute(elem) {
          return elem.getAttribute(attr);
        };
      } // otherwise, assume its a querySelector, and get its text


      return function getChildText(elem) {
        var child = elem.querySelector(query);
        return child && child.textContent;
      };
    }

    return mungeSorter;
  }(); // parsers used in getSortData shortcut strings


  Isotope.sortDataParsers = {
    "parseInt": function (val) {
      return parseInt(val, 10);
    },
    "parseFloat": function (val) {
      return parseFloat(val);
    }
  }; // ----- sort method ----- //
  // sort filteredItem order

  proto._sort = function () {
    if (!(this || _global).options.sortBy) {
      return;
    } // keep track of sortBy History


    var sortBys = utils.makeArray((this || _global).options.sortBy);

    if (!this._getIsSameSortBy(sortBys)) {
      // concat all sortBy and sortHistory, add to front, oldest goes in last
      (this || _global).sortHistory = sortBys.concat((this || _global).sortHistory);
    } // sort magic


    var itemSorter = getItemSorter((this || _global).sortHistory, (this || _global).options.sortAscending);

    (this || _global).filteredItems.sort(itemSorter);
  }; // check if sortBys is same as start of sortHistory


  proto._getIsSameSortBy = function (sortBys) {
    for (var i = 0; i < sortBys.length; i++) {
      if (sortBys[i] != (this || _global).sortHistory[i]) {
        return false;
      }
    }

    return true;
  }; // returns a function used for sorting


  function getItemSorter(sortBys, sortAsc) {
    return function sorter(itemA, itemB) {
      // cycle through all sortKeys
      for (var i = 0; i < sortBys.length; i++) {
        var sortBy = sortBys[i];
        var a = itemA.sortData[sortBy];
        var b = itemB.sortData[sortBy];

        if (a > b || a < b) {
          // if sortAsc is an object, use the value given the sortBy key
          var isAscending = sortAsc[sortBy] !== undefined ? sortAsc[sortBy] : sortAsc;
          var direction = isAscending ? 1 : -1;
          return (a > b ? 1 : -1) * direction;
        }
      }

      return 0;
    };
  } // -------------------------- methods -------------------------- //
  // get layout mode


  proto._mode = function () {
    var layoutMode = (this || _global).options.layoutMode;
    var mode = (this || _global).modes[layoutMode];

    if (!mode) {
      // TODO console.error
      throw new Error("No layout mode: " + layoutMode);
    } // HACK sync mode's options
    // any options set after init for layout mode need to be synced


    mode.options = (this || _global).options[layoutMode];
    return mode;
  };

  proto._resetLayout = function () {
    // trigger original reset layout
    Outlayer.prototype._resetLayout.call(this || _global);

    this._mode()._resetLayout();
  };

  proto._getItemLayoutPosition = function (item) {
    return this._mode()._getItemLayoutPosition(item);
  };

  proto._manageStamp = function (stamp) {
    this._mode()._manageStamp(stamp);
  };

  proto._getContainerSize = function () {
    return this._mode()._getContainerSize();
  };

  proto.needsResizeLayout = function () {
    return this._mode().needsResizeLayout();
  }; // -------------------------- adding & removing -------------------------- //
  // HEADS UP overwrites default Outlayer appended


  proto.appended = function (elems) {
    var items = this.addItems(elems);

    if (!items.length) {
      return;
    } // filter, layout, reveal new items


    var filteredItems = this._filterRevealAdded(items); // add to filteredItems


    (this || _global).filteredItems = (this || _global).filteredItems.concat(filteredItems);
  }; // HEADS UP overwrites default Outlayer prepended


  proto.prepended = function (elems) {
    var items = this._itemize(elems);

    if (!items.length) {
      return;
    } // start new layout


    this._resetLayout();

    this._manageStamps(); // filter, layout, reveal new items


    var filteredItems = this._filterRevealAdded(items); // layout previous items


    this.layoutItems((this || _global).filteredItems); // add to items and filteredItems

    (this || _global).filteredItems = filteredItems.concat((this || _global).filteredItems);
    (this || _global).items = items.concat((this || _global).items);
  };

  proto._filterRevealAdded = function (items) {
    var filtered = this._filter(items);

    this.hide(filtered.needHide); // reveal all new items

    this.reveal(filtered.matches); // layout new items, no transition

    this.layoutItems(filtered.matches, true);
    return filtered.matches;
  };
  /**
   * Filter, sort, and layout newly-appended item elements
   * @param {Array or NodeList or Element} elems
   */


  proto.insert = function (elems) {
    var items = this.addItems(elems);

    if (!items.length) {
      return;
    } // append item elements


    var i, item;
    var len = items.length;

    for (i = 0; i < len; i++) {
      item = items[i];

      (this || _global).element.appendChild(item.element);
    } // filter new stuff


    var filteredInsertItems = this._filter(items).matches; // set flag


    for (i = 0; i < len; i++) {
      items[i].isLayoutInstant = true;
    }

    this.arrange(); // reset flag

    for (i = 0; i < len; i++) {
      delete items[i].isLayoutInstant;
    }

    this.reveal(filteredInsertItems);
  };

  var _remove = proto.remove;

  proto.remove = function (elems) {
    elems = utils.makeArray(elems);
    var removeItems = this.getItems(elems); // do regular thing

    _remove.call(this || _global, elems); // bail if no items to remove


    var len = removeItems && removeItems.length; // remove elems from filteredItems

    for (var i = 0; len && i < len; i++) {
      var item = removeItems[i]; // remove item from collection

      utils.removeFrom((this || _global).filteredItems, item);
    }
  };

  proto.shuffle = function () {
    // update random sortData
    for (var i = 0; i < (this || _global).items.length; i++) {
      var item = (this || _global).items[i];
      item.sortData.random = Math.random();
    }

    (this || _global).options.sortBy = "random";

    this._sort();

    this._layout();
  };
  /**
   * trigger fn without transition
   * kind of hacky to have this in the first place
   * @param {Function} fn
   * @param {Array} args
   * @returns ret
   * @private
   */


  proto._noTransition = function (fn, args) {
    // save transitionDuration before disabling
    var transitionDuration = (this || _global).options.transitionDuration; // disable transition

    (this || _global).options.transitionDuration = 0; // do it

    var returnValue = fn.apply(this || _global, args); // re-enable transition for reveal

    (this || _global).options.transitionDuration = transitionDuration;
    return returnValue;
  }; // ----- helper methods ----- //

  /**
   * getter method for getting filtered item elements
   * @returns {Array} elems - collection of item elements
   */


  proto.getFilteredItemElements = function () {
    return (this || _global).filteredItems.map(function (item) {
      return item.element;
    });
  }; // -----  ----- //


  return Isotope;
});

export default exports;